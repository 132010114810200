@import "../../../../assets/styles/common/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.projects {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  &-header {
    span {
      font-family: $font-family-base;
    }
  }
}